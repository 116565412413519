<template>
  <BasicModal
    :title="title"
    width="500px"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="ok"
    @cancel="cancel"
  >
    <BasicForm
      ref="formRef"
      :labelCol="{ span: 5 }"
      :formItemsMap="formItemsMap"
      v-model:formData="formData"
    ></BasicForm>
  </BasicModal>
</template>

<script setup>
import {
  defineProps,
  computed,
  ref,
  defineEmits,
  reactive,
  onMounted,
} from "vue";
import { message } from "ant-design-vue";
import { apiProduct } from "@/api/IoT/productManage";
import { apiDictListByCode } from "@/api/common.js";
import { formatTreeSelectable } from "@/utils/util";

const props = defineProps({
  detail: {
    type: Object,
    default: null,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef = ref(null);

const title = computed(() => `${props.detail ? "修改" : "添加"}产品`);

onMounted(() => {
  initFormItems();
  initFormData();
});

const formItemsMap = reactive({
  name: {
    label: "产品名称",
    prop: "name",
    type: "input",
    required: true,
    requiredMessage: "请输入",
  },
  categoryId: {
    label: "所属品类",
    prop: "categoryId",
    type: "treeSelect",
    fieldNames: {
      children: "children",
      label: "name",
      value: "id",
    },
    options: [],
    required: true,
    requiredMessage: "请选择",
  },
  isReal: {
    label: "实体产品",
    prop: "isReal",
    type: "radio",
    options: [
      {
        label: "是",
        value: true,
      },
      {
        label: "否",
        value: false,
      },
    ],
    required: true,
    requiredMessage: "请选择",
  },
  isLongOnline: {
    label: "⻓连设备",
    prop: "isLongOnline",
    type: "radio",
    options: [
      {
        label: "是",
        value: true,
      },
      {
        label: "否",
        value: false,
      },
    ],
    required: true,
    requiredMessage: "请选择",
  },
  communicationType: {
    label: "联⽹⽅式",
    prop: "communicationType",
    type: "select",
    options: [],
    required: true,
    requiredMessage: "请选择",
  },
  timeout: {
    label: "通讯超时时间",
    prop: "timeout",
    type: "input",
    suffix: "秒",
    inputType: "number",
    required: true,
    requiredMessage: "请输入",
  },
  factory: {
    label: "产品⼚商",
    prop: "factory",
    type: "input",
  },
  model: {
    label: "产品型号",
    prop: "model",
    type: "input",
    required: true,
    requiredMessage: "请输入",
  },
  description: {
    label: "产品描述",
    prop: "description",
    type: "textarea",
  },
});
const initFormItems = async () => {
  formItemsMap.categoryId.disabled = !!props.detail;
  apiProduct.allCategoryTree({ containTop: false }).then((res) => {
    formatTreeSelectable(res.result, "isCatalogue", false);
    console.log(res.result);
    formItemsMap.categoryId.options = res.result;
  });
  apiDictListByCode("PRODUCT_COMMUNICATION_TYPE").then((res) => {
    formItemsMap.communicationType.options = res.result;
  });
};
const formData = reactive({
  name: "",
  categoryId: undefined,
  isReal: undefined,
  isLongOnline: undefined,
  communicationType: undefined,
  timeout: "",
  factory: "",
  model: "",
  description: "",
});

const initFormData = async () => {
  if (!props.detail) return;
  for (const key in formData) {
    formData[key] = props.detail[key];
  }
};

const confirmLoading = ref(false);
const ok = async () => {
  try {
    await formRef.value.formRef.validateFields();
    const params = {
      ...formData,
    };
    if (props.detail) {
      // 修改
      params.id = props.detail.id;
    }
    confirmLoading.value = true;
    await apiProduct[props.detail ? "edit" : "add"](params);
    message.success(`${props.detail ? "修改" : "添加"}成功`);
    emit("update:visible", false);
    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped></style>
