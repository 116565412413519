<template>
  <TwoColumnsLayout leftWidth="240px">
    <template #left>
      <LeftSelectTree
        title="品类树"
        showIcon
        :selected="selected"
        :treeData="treeData"
        :loading="treeLoading"
        :fieldNames="{
          title: 'name',
          key: 'id',
        }"
        @select="selectTree"
      ></LeftSelectTree>
    </template>
    <template #right>
      <ComponentTabs
        :tabMap="tabMap"
        :activeKey="activeKey"
        @change="changeTab"
      >
        <div class="product-list-search">
          <CategorySearch :source="filterSource" @change="search" />
          <a-button type="primary" @click="showModal(null)">添加</a-button>
        </div>
        <BasicTable
          ref="tableRef"
          v-bind="tableConfig"
          @page-change="pageChange"
        >
          <template #slotID="{ row }">
            <router-link
              :to="{
                path: `/IoTManage/productCenter/productDetail/${row.id}`,
                state: { tabName: row.name },
              }"
              >{{ row.bizId }}</router-link
            >
          </template>
          <template #slotIsReal="{ row }">
            {{ row.isReal ? "是" : "否" }}
          </template>
          <template #slotIsLongOnline="{ row }">
            {{ row.isLongOnline ? "是" : "否" }}
          </template>
        </BasicTable>
      </ComponentTabs>
    </template>
  </TwoColumnsLayout>
  <ProductEditModal
    v-if="modalVisible"
    v-model:visible="modalVisible"
    :detail="currentRow"
    @success="editSuccess"
  ></ProductEditModal>
</template>

<script setup>
import { watch, ref, reactive, onMounted } from "vue";
import { apiProduct } from "@/api/IoT/productManage.js";
import { useTreeState } from "@/components/business/LeftSelectTree/hooks/useTreeState";
import { useTabState } from "@/components/basic/tabs/hooks/useTabState";
import CategorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from "@/components/basic/table";
import { Modal, message } from "ant-design-vue";
import ProductEditModal from "./components/ProductEditModal";

const {
  treeData,
  treeLoading,
  selected,
  selectTree,
  initTreeData,
  selectedItem,
} = useTreeState({
  apiFunc: apiProduct.categoryTree,
  initSelected: () => {
    if (!selected.value || !selectedItem.value) {
      selectTree(treeData.value[0]?.id ?? "");
    }
  },
});
watch(selected, () => {
  searchData = {};
  tableRef.value.paginationProps.current = 1;
  initTableData();
});

const tabMap = {
  all: {
    label: "全部",
    value: "",
  },
  enable: {
    label: "启用",
    value: "0",
  },
  disable: {
    label: "停用",
    value: "1",
  },
};
const { changeTab, activeKey } = useTabState(tabMap);
watch(activeKey, () => {
  searchData = {};
  tableRef.value.paginationProps.current = 1;
  initTableData();
});

const filterSource = ref([
  {
    label: "产品名称",
    type: "input",
    bindKey: "name",
    placeholder: "请输入",
  },
]);
let searchData = {};
const search = (data) => {
  searchData = data;
  tableRef.value.paginationProps.current = 1;
  initTableData();
};

const tableRef = ref();
const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  remote: false,
  loading: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 320px)",
  },
  columns: [
    {
      title: "ID",
      type: "customize",
      slotName: "slotID",
      width: 100,
    },
    {
      title: "产品名称",
      type: "text",
      key: "name",
      width: 180,
      ellipsis: true,
    },
    {
      title: "所属品类",
      type: "text",
      key: "categoryName",
      width: 180,
    },
    {
      title: "产品状态",
      type: "text",
      key: "statusLabel",
      width: 80,
    },
    {
      title: "实体设备",
      type: "customize",
      slotName: "slotIsReal",
      width: 80,
    },
    {
      title: "⻓连设备",
      type: "customize",
      slotName: "slotIsLongOnline",
      width: 80,
    },
    {
      title: "联⽹⽅式",
      type: "text",
      key: "communicationTypeLabel",
      width: 80,
    },
    {
      title: "通讯超时时间（秒）",
      type: "text",
      key: "timeout",
      width: 150,
      ellipsis: true,
    },
  ],
  action: {
    title: "操作",
    width: 70,
    fixed: "right",
    items: [
      {
        type: "icon",
        icon: "CloseSquareOutlined",
        iconTip: "移除",
        visibleFn: (row) => row.isRepo && !row.refDevices,
        onClick: (row) => {
          console.log(row);
          Modal.confirm({
            title: "提示",
            content: "是否移除该产品？",
            okType: "danger",
            onOk: () => {
              return new Promise((resolve, reject) => {
                apiProduct
                  .deleteRef(row.id)
                  .then(() => {
                    message.success("移除成功");
                    refreshData();
                    resolve();
                  })
                  .catch(() => {
                    reject();
                  });
              });
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        },
      },
      {
        type: "icon",
        icon: "FormOutlined",
        iconTip: "修改",
        visibleFn: (row) => !row.isRepo,
        onClick: (row) => {
          showModal(row);
        },
      },
      {
        type: "icon",
        icon: "DeleteOutlined",
        visibleFn: (row) => row.status === 1 && !row.isRepo && !row.refDevices,
        iconTip: "删除",
        onClick: (row) => {
          Modal.confirm({
            title: "提示",
            content: "是否删除该产品？",
            okType: "danger",
            onOk: () => {
              return new Promise((resolve, reject) => {
                apiProduct
                  .delete(row.id)
                  .then(async () => {
                    message.success("删除成功");
                    refreshData();
                    resolve();
                  })
                  .catch(() => {
                    reject();
                  });
              });
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        },
      },
    ],
  },
});
const initTableData = async () => {
  if (!selected.value) return;
  if (!selectedItem.value) return;
  const { current, pageSize } = tableRef.value.paginationProps;
  const params = {
    pageNo: current,
    pageSize,
    categoryId: selected.value,
    isCatalogue: selectedItem.value.isCatalogue,
    ...searchData,
  };
  if (tabMap[activeKey.value].value) {
    params.status = tabMap[activeKey.value].value;
  }
  tableConfig.loading = true;
  try {
    const res = await apiProduct.page(params);
    const { records, total } = res?.result ?? {};
    tableConfig.loading = false;
    tableRef.value.dataSourceRef = records;
    tableRef.value.paginationProps.total = total;
  } catch (error) {
    tableConfig.loading = false;
    console.log(error);
  }
};
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

const modalVisible = ref(false);
const currentRow = ref(null);
const showModal = (row) => {
  currentRow.value = row;
  modalVisible.value = true;
};
const editSuccess = async () => {
  refreshData();
};

const refreshData = async () => {
  await initTreeData();
  initTableData();
};

onMounted(() => {
  tableRef.value.paginationProps.pageSize = 20;
  initTableData();
});
</script>

<style lang="less" scoped>
.product-list-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  ::v-deep .category-search {
    width: 600px;
    margin-bottom: 0;
  }
  ::v-deep .container_wrap {
    width: 560px !important;
    overflow-x: hidden !important;
  }
}
</style>
