import { ref, onActivated, computed, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { filterArrayDeep } from "@/utils/util";

let _firstItem = null;
function getFirstItemByDeep(list) {
  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    if (i !== 0) break;
    if (item.selectable) {
      _firstItem = item;
      return;
    } else if (!item.children) {
      break;
    } else {
      getFirstItemByDeep(item.children);
    }
  }
}

export function useTreeState({
  apiFunc,
  apiParams = {},
  initSelected,
  queryKey = "treeSelected",
  filterArrayDeepValueKey = "id",
}) {
  const router = useRouter();
  const route = useRoute();
  const firstLeafItem = ref(null);
  const treeData = ref([]);
  const treeLoading = ref(false);
  const selected = ref("");
  const selectedItem = computed(
    () =>
      filterArrayDeep(
        treeData.value,
        selected.value,
        filterArrayDeepValueKey
      ) || null
  );

  const initTreeData = async () => {
    treeLoading.value = true;
    try {
      const { result } = await apiFunc(apiParams);
      const _list = Array.isArray(result) ? result : [result];
      treeData.value = _list;
      getFirstItemByDeep(_list);
      firstLeafItem.value = _firstItem;
      selected.value = route?.query[queryKey] ?? "";
      initSelected();
    } catch (error) {
      console.log(error);
    }
    treeLoading.value = false;
  };

  const selectTree = (value) => {
    selected.value = value;
    const query = {
      ...route.query,
    };
    query[queryKey] = value;
    router.replace({
      path: route.fullPath,
      query,
    });
  };

  onActivated(() => {
    initTreeData();
  });
  onMounted(() => {
    initTreeData();
  });

  return {
    treeData,
    treeLoading,
    firstLeafItem,
    selected,
    selectTree,
    initTreeData,
    selectedItem,
  };
}
